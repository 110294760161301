<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ contact.id ? 'Edit' : 'New' }} contact<br>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to areas & groups list"
              @click="$router.push({name: 'admin.institution-contacts.index'})"
            ><i data-feather="chevron-left" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Basic information</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >First name</label>
                        <input
                          v-model="contact.first_name"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Last name</label>
                        <input
                          v-model="contact.last_name"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Email</label>
                        <input
                          v-model="contact.email"
                          type="mail"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Status</label>
                        <select
                          v-model="contact.status"
                          class="form-select"
                        >
                          <option
                            value="true"
                          >
                            Active
                          </option>
                          <option
                            value="false"
                          >
                            Arxived
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-2">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Position</label>
                        <v-select
                          v-model="contact.position"
                          label="name"
                          :clearable="false"
                          :options="positions"
                          :get-option-key="option => option.id"
                          @search="onSearch({ name: $event }, 'institutions/fetchPositions')"
                        />
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Title of position</label>
                        <input
                          v-model="contact.position_title"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Host institution</label>
                        <v-select
                          v-model="contact.host"
                          label="name"
                          :clearable="false"
                          :options="hosts"
                          :get-option-key="option => option.id"
                          @search="onSearch({ name: $event }, 'institutions/filter')"
                          @input="contact.department = null"
                        />
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Department</label>
                        <v-select
                          v-model="contact.department"
                          label="name"
                          :clearable="false"
                          :options="contact.host && !contact.host.departments ? contact.departments : (contact.host ? contact.host.departments : [])"
                          :get-option-key="option => option.id"
                        />
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Department unit</label>
                        <input
                          v-model="contact.department_unit"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Phone</label>
                        <input
                          v-model="contact.phone"
                          type="number"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Mobile phone</label>
                        <input
                          v-model="contact.mobile_phone"
                          type="number"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Email</label>
                        <input
                          v-model="contact.email"
                          type="mail"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Gender</label>
                        <v-select
                          v-model="contact.genre"
                          label="name"
                          :options="genders"
                          :get-option-key="option => option.id"
                        />
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Notes</label>
                        <quill-editor v-model="contact.notes" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4 align-items-center form-switch d-flex ps-1">
                        <div class="mb-1">
                          <label
                            class="form-check-label"
                            for="publishContent1"
                          ><small>Mark as Evaluator host</small></label>
                          <input
                            id="publishContent1"
                            v-model="contact.evaluatorHost"
                            type="checkbox"
                            class="form-check-input ms-2"
                            value="1"
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div
                v-b-toggle="`collapseHistory`"
                class="card-header"
              >
                <h3 class="cart-title">
                  <a>Head history</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <b-collapse
                :id="`collapseHistory`"
              >
                <div class="card-body">

                  <div class="row">
                    <div class="col">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Host institution</th>
                            <th>Department</th>
                            <th>Period</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="hist in history"
                            :key="hist.id"
                          >
                            <td>{{ hist.host ? hist.host.name : '-' }}</td>
                            <td>{{ hist.department ? hist.department.name : '-' }}</td>
                            <td>{{ hist.from }} to {{ hist.to }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-actions">
      <button
        v-if="!sending"
        id="type-success"
        class="btn btn-success"
        @click="save"
      >
        <i data-feather="save" />
        <span class="ms-25 align-middle">Save and Exit</span>
      </button>
      <button
        v-else
        class="btn btn-outline-success"
        type="button"
        disabled=""
      >
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
        <span class="ms-25 align-middle">Saving...</span>
      </button>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    vSelect,
    BCollapse,
  },
  data() {
    return {
      contactId: this.$route.params.id,
      parents: {},
      sending: false,
      errors: '',
      history: [],
    }
  },
  computed: {
    ...mapGetters({
      contact: 'institutions/contact',
      departments: 'institutions/departments',
      positions: 'institutions/positions',
      hosts: 'institutions/institutions',
      genders: 'genres/items',
    }),
  },
  async mounted() {
    this.$store.dispatch('genres/fetch')
    await this.$store.dispatch('institutions/fetchPositions')
    if (this.contactId) {
      await this.$store.dispatch('institutions/fetchContact', this.contactId)
      Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/institutions/contacts/get-history/${this.contactId}`).then(resp => {
        this.history = resp.data.data
      })
      this.contact.evaluatorHost = !!this.contact.evaluator
    } else {
      await this.$store.dispatch('institutions/clearContact')
    }
  },
  methods: {
    async save() {
      this.sending = true

      try {
        if (this.contactId) {
          await this.$store.dispatch('institutions/updateContact', {
            id: this.contactId,
            data: this.contact,
          })
        } else {
          await this.$store.dispatch(
            'institutions/createContact',
            this.contact,
          )
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.contact.id) {
        Vue.swal('The contact has been saved correctly!', '', 'success').then(
          () => {
            this.$router.push({ name: 'admin.institution-contacts.index' })
          },
        )
      } else {
        // Vue.swal(
        //   'Error on saving the contact.',
        //   'Please, fill up all the required fields',
        //   'error',
        // )
      }

      this.sending = false
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
  },
}
</script>
